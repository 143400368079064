























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Menu: () => import('@/components/business/mobile/header/InsElMenu.vue')
  }
})
export default class InsMenuLayout extends Vue {
  showMemNav: boolean = false;
  searchKey: string = '';
  private now:Date = new Date();
  private showLang:boolean = false;
  handleOpen (key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose (key, keyPath) {
    console.log(key, keyPath);
  }

  closeSlideMenu () {
    this.$store.dispatch('isShowMenu', false);
  }

  private changLange (lang) {
    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);
      this.Reload();
      this.closeSlideMenu();
    }).catch((error) => {
      console.log(error);
    });
  }

  search () {
    // this.$store.dispatch('setSearchKey', this.searchKey);
    this.closeSlideMenu();
    this.$router.push({ path: `/product/search/${this.searchKey}` });
    this.searchKey = '';
  }
  get currentYear () {
    return this.now.getFullYear();
  }
}
